import { fetcher } from '../fetcher';

export const authService = {
  login: async (payload: LoginProps) => {
    return await fetcher(`/login`, {
      method: 'POST',
      body: payload,
      schema: userSchema,
    });
  },
  logout: async () => {
    return await fetcher(`/logout`, {
      method: 'GET',
    });
  },
  register: async (payload: RegisterProps) => {
    return await fetcher(`/register`, {
      method: 'POST',
      body: {
        name: `${payload.fist_name} ${payload.last_name}`,
        email: payload.email,
        password: payload.password,
      },
      schema: userSchema,
    });
  },
  remindPasswordEmail: async (payload: RemindPasswordEmailProps) => {
    return await fetcher(`/password/email`, {
      method: 'POST',
      body: payload,
    });
  },
  remindPasswordReset: async (payload: RemindPasswordResetProps) => {
    return await fetcher(`/password/reset`, {
      method: 'POST',
      body: payload,
    });
  },
};
