<script setup lang="ts">
const { isDarkTheme } = useTheme();
</script>

<template>
  <svg
    class="w-[105px]"
    v-if="isDarkTheme"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1420 300"
    fill="none"
  >
    <g clip-path="url(#clip0_1253_5680)">
      <path
        class="letter"
        d="M319.82 298.18L318.77 287.81L464.02 28.8501H361.53L346.02 79.2701L334.28 80.9301L323.17 8.72006L324.43 7.06006C360.06 7.61006 393.6 7.89006 425.04 7.89006H479.95C499.93 7.89006 519.98 7.61006 540.1 7.06006L541.15 17.6401L396.32 276.19H501.75L531.3 215.6L543.46 213.73L537.8 296.73L536.54 298.18C496.85 297.77 461.64 297.56 430.9 297.56H374.73C355.16 297.56 336.86 297.77 319.82 298.18Z"
        fill="white"
      />
      <path
        class="letter"
        d="M838.99 7.06006C858.27 7.61006 875.67 7.89006 891.18 7.89006C905.01 7.89006 921.29 7.61006 940.02 7.06006L941.28 17.6401L912.57 25.7401C909.35 26.7001 907.67 28.9801 907.54 32.5801C907.25 41.7101 907.12 53.2001 907.12 67.0301V232.4C907.12 248.73 907.25 262.49 907.54 273.7C907.67 276.89 909.56 279.02 913.2 280.13L940.45 287.81L941.29 298.18C921.3 297.77 899.16 297.56 874.85 297.56C850.54 297.56 831.46 297.77 815.53 298.18L814.48 287.81L838.58 280.54C841.93 279.58 843.68 277.09 843.82 273.08C844.24 262.28 844.45 248.73 844.45 232.4V99.6001C844.45 92.4201 844.66 78.6501 845.08 58.3101C840.33 70.2201 835.3 82.5201 829.99 95.2501L746.57 297.56L727.71 299.22L648.06 102.72C643.31 91.5101 638.42 78.9901 633.39 65.1601C633.67 84.8001 633.81 97.7401 633.81 103.96V232.4C633.81 248.73 633.94 262.28 634.23 273.08C634.36 277.09 636.18 279.58 639.68 280.54L662.74 287.81L663.37 298.18C646.88 297.77 632.56 297.56 620.4 297.56C608.24 297.56 592.66 297.77 573.24 298.18L572.19 287.81L600.28 280.13C603.91 279.02 605.8 276.89 605.94 273.7C606.22 262.49 606.36 248.73 606.36 232.4V67.0301C606.36 53.2001 606.22 41.7101 605.94 32.5801C605.8 28.9801 604.12 26.7001 600.91 25.7401L572.82 17.6401L572.19 7.06006C595.38 7.61006 612.78 7.89006 624.38 7.89006C640.31 7.89006 656.58 7.61006 673.22 7.06006L746.37 189.87C749.58 197.75 752.45 205.22 754.96 212.28C757.75 205.22 760.69 197.75 763.76 189.87L838.99 7.06006Z"
        fill="white"
      />
      <path
        class="letter"
        d="M969.36 114.54C1003.73 109.43 1033.92 104.1 1059.91 98.57L1062.22 100.23C1061.38 119.32 1060.96 139.65 1060.96 161.23V239.46C1060.96 255.93 1061.09 267.4 1061.38 273.9C1061.66 277.64 1063.4 280 1066.62 280.96L1092.82 288.22L1093.45 298.18C1072.77 297.77 1052.23 297.56 1031.83 297.56C1011.43 297.56 990.46 297.77 970.21 298.18L969.16 288.22L995.78 280.96C998.99 280.13 1000.73 278.06 1001.02 274.74C1001.3 269.34 1001.44 260.29 1001.44 247.55V180.74C1001.44 162.61 1001.15 145.95 1000.6 130.73L970.63 123.05L969.37 114.54H969.36ZM1061.58 29.88C1061.58 39.43 1058.47 47.18 1052.25 53.12C1046.03 59.07 1037.9 62.05 1027.84 62.05C1018.62 62.05 1011.03 59.25 1005.09 53.64C999.15 48.04 996.19 40.75 996.19 31.75C996.19 21.93 999.33 14.19 1005.62 8.51C1011.91 2.84 1020.08 0 1030.14 0C1039.36 0 1046.91 2.6 1052.78 7.79C1058.65 12.97 1061.58 20.34 1061.58 29.88Z"
        fill="white"
      />
      <path
        class="letter"
        d="M1176.86 297.56C1158.83 297.56 1140.25 297.77 1121.11 298.18L1120.06 287.81L1148.15 280.13C1151.78 279.02 1153.67 276.89 1153.81 273.7C1153.94 268.17 1154.05 261.97 1154.12 255.13C1154.19 248.28 1154.23 240.7 1154.23 232.4V67.0301C1154.23 60.1101 1154.19 53.8201 1154.12 48.1401C1154.05 42.4701 1153.94 37.2801 1153.81 32.5801C1153.67 28.9801 1151.99 26.7001 1148.78 25.7401L1120.69 17.6401L1120.06 7.06007C1139.62 7.61007 1158.34 7.89007 1176.23 7.89007C1191.32 7.89007 1205.78 7.55007 1219.62 6.85007C1233.45 6.16007 1247.77 5.82007 1262.59 5.82007C1314.7 5.82007 1353.9 16.8501 1380.17 38.9101C1406.44 60.9801 1419.57 93.7201 1419.57 137.16C1419.57 188.34 1405 228.08 1375.86 256.37C1346.73 284.67 1305.75 298.8 1252.94 298.8C1238.4 298.8 1225.48 298.6 1214.16 298.18C1202.84 297.77 1190.4 297.56 1176.85 297.56H1176.86ZM1217.1 276.4C1223.81 277.64 1230.37 278.44 1236.8 278.78C1243.22 279.13 1249.72 279.3 1256.29 279.3C1286.61 279.3 1309.66 268.72 1325.46 247.55C1341.25 226.39 1349.14 194.15 1349.14 150.86C1349.14 107.57 1341.59 77.3701 1326.5 56.9601C1311.41 36.5601 1287.65 26.3601 1255.24 26.3601C1243.22 26.3601 1230.51 26.9101 1217.09 28.0201V276.4H1217.1Z"
        fill="white"
      />
      <path
        d="M119.94 6.72997L173.62 242.28L115.57 299.79L58.09 242.05L112.48 6.71997L119.94 6.72997Z"
        fill="white"
      />
      <path
        d="M83.46 7.81006L29.43 241.15V241.21L28.96 243.3H0.7H0V243.24L54.91 7.81006H83.46Z"
        fill="white"
      />
      <path
        d="M149.24 7.93994L202.31 241.5V241.56L202.77 243.65L231.03 243.77H231.72V243.71L177.79 8.05994L149.24 7.93994Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1253_5680">
        <rect width="1419.57" height="299.79" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else
    class="w-[105px]"
    viewBox="0 0 1420 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1208_5616)">
      <path
        class="letter"
        d="M319.82 298.18L318.77 287.81L464.02 28.8501H361.53L346.02 79.2701L334.28 80.9301L323.17 8.72006L324.43 7.06006C360.06 7.61006 393.6 7.89006 425.04 7.89006H479.95C499.93 7.89006 519.98 7.61006 540.1 7.06006L541.15 17.6401L396.32 276.19H501.75L531.3 215.6L543.46 213.73L537.8 296.73L536.54 298.18C496.85 297.77 461.64 297.56 430.9 297.56H374.73C355.16 297.56 336.86 297.77 319.82 298.18Z"
        fill="#22335F"
      />
      <path
        class="letter"
        d="M838.99 7.06006C858.27 7.61006 875.67 7.89006 891.18 7.89006C905.01 7.89006 921.29 7.61006 940.02 7.06006L941.28 17.6401L912.57 25.7401C909.35 26.7001 907.67 28.9801 907.54 32.5801C907.25 41.7101 907.12 53.2001 907.12 67.0301V232.4C907.12 248.73 907.25 262.49 907.54 273.7C907.67 276.89 909.56 279.02 913.2 280.13L940.45 287.81L941.29 298.18C921.3 297.77 899.16 297.56 874.85 297.56C850.54 297.56 831.46 297.77 815.53 298.18L814.48 287.81L838.58 280.54C841.93 279.58 843.68 277.09 843.82 273.08C844.24 262.28 844.45 248.73 844.45 232.4V99.6001C844.45 92.4201 844.66 78.6501 845.08 58.3101C840.33 70.2201 835.3 82.5201 829.99 95.2501L746.57 297.56L727.71 299.22L648.06 102.72C643.31 91.5101 638.42 78.9901 633.39 65.1601C633.67 84.8001 633.81 97.7401 633.81 103.96V232.4C633.81 248.73 633.94 262.28 634.23 273.08C634.36 277.09 636.18 279.58 639.68 280.54L662.74 287.81L663.37 298.18C646.88 297.77 632.56 297.56 620.4 297.56C608.24 297.56 592.66 297.77 573.24 298.18L572.19 287.81L600.28 280.13C603.91 279.02 605.8 276.89 605.94 273.7C606.22 262.49 606.36 248.73 606.36 232.4V67.0301C606.36 53.2001 606.22 41.7101 605.94 32.5801C605.8 28.9801 604.12 26.7001 600.91 25.7401L572.82 17.6401L572.19 7.06006C595.38 7.61006 612.78 7.89006 624.38 7.89006C640.31 7.89006 656.58 7.61006 673.22 7.06006L746.37 189.87C749.58 197.75 752.45 205.22 754.96 212.28C757.75 205.22 760.69 197.75 763.76 189.87L838.99 7.06006Z"
        fill="#22335F"
      />
      <path
        class="letter"
        d="M969.36 114.54C1003.73 109.43 1033.92 104.1 1059.91 98.57L1062.22 100.23C1061.38 119.32 1060.96 139.65 1060.96 161.23V239.46C1060.96 255.93 1061.09 267.4 1061.38 273.9C1061.66 277.64 1063.4 280 1066.62 280.96L1092.82 288.22L1093.45 298.18C1072.77 297.77 1052.23 297.56 1031.83 297.56C1011.43 297.56 990.46 297.77 970.21 298.18L969.16 288.22L995.78 280.96C998.99 280.13 1000.73 278.06 1001.02 274.74C1001.3 269.34 1001.44 260.29 1001.44 247.55V180.74C1001.44 162.61 1001.15 145.95 1000.6 130.73L970.63 123.05L969.37 114.54H969.36ZM1061.58 29.88C1061.58 39.43 1058.47 47.18 1052.25 53.12C1046.03 59.07 1037.9 62.05 1027.84 62.05C1018.62 62.05 1011.03 59.25 1005.09 53.64C999.15 48.04 996.19 40.75 996.19 31.75C996.19 21.93 999.33 14.19 1005.62 8.51C1011.91 2.84 1020.08 0 1030.14 0C1039.36 0 1046.91 2.6 1052.78 7.79C1058.65 12.97 1061.58 20.34 1061.58 29.88Z"
        fill="#22335F"
      />
      <path
        class="letter"
        d="M1176.86 297.56C1158.83 297.56 1140.25 297.77 1121.11 298.18L1120.06 287.81L1148.15 280.13C1151.78 279.02 1153.67 276.89 1153.81 273.7C1153.94 268.17 1154.05 261.97 1154.12 255.13C1154.19 248.28 1154.23 240.7 1154.23 232.4V67.0299C1154.23 60.1099 1154.19 53.8199 1154.12 48.1399C1154.05 42.4699 1153.94 37.2799 1153.81 32.5799C1153.67 28.9799 1151.99 26.6999 1148.78 25.7399L1120.69 17.6399L1120.06 7.05995C1139.62 7.60995 1158.34 7.88995 1176.23 7.88995C1191.32 7.88995 1205.78 7.54995 1219.62 6.84995C1233.45 6.15995 1247.77 5.81995 1262.59 5.81995C1314.7 5.81995 1353.9 16.8499 1380.17 38.9099C1406.44 60.9799 1419.57 93.7199 1419.57 137.16C1419.57 188.34 1405 228.08 1375.86 256.37C1346.73 284.67 1305.75 298.8 1252.94 298.8C1238.4 298.8 1225.48 298.6 1214.16 298.18C1202.84 297.77 1190.4 297.56 1176.85 297.56H1176.86ZM1217.1 276.4C1223.81 277.64 1230.37 278.44 1236.8 278.78C1243.22 279.13 1249.72 279.3 1256.29 279.3C1286.61 279.3 1309.66 268.72 1325.46 247.55C1341.25 226.39 1349.14 194.15 1349.14 150.86C1349.14 107.57 1341.59 77.3699 1326.5 56.9599C1311.41 36.5599 1287.65 26.3599 1255.24 26.3599C1243.22 26.3599 1230.51 26.9099 1217.09 28.0199V276.4H1217.1Z"
        fill="#22335F"
      />
      <path
        d="M119.94 6.72997L173.62 242.28L115.57 299.79L58.09 242.05L112.48 6.71997L119.94 6.72997Z"
        fill="#22335F"
      />
      <path
        d="M83.46 7.81006L29.43 241.15V241.21L28.96 243.3H0.7H0V243.24L54.91 7.81006H83.46Z"
        fill="#D71016"
      />
      <path
        d="M149.24 7.93994L202.31 241.5V241.56L202.77 243.65L231.03 243.77H231.72V243.71L177.79 8.05994L149.24 7.93994Z"
        fill="#D71016"
      />
    </g>
    <defs>
      <clipPath id="clip0_1208_5616">
        <rect width="1419.57" height="299.79" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
